<template>
  <v-container>
    <div class="text-center">
      <h1>🐣 Admin 🐣</h1>
      <v-row>
        <v-col>
          <v-btn to="/addEvent" color="primary">
            Skapa event
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn to="/adminEventList" color="primary">
            Redigera event
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn to="/adminQuestionBox" color="primary">
            Frågelåda
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn to="/formsAnswers" color="primary">
            Forms
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn to="/logout" color="error">Logga ut</v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Admin',
  components: {
  },
  data: () => ({
  })
}
</script>
